var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4e67997cd2e5f4a761e309e5501a97605693d44d"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const SAMPLE_RATE = Number(process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE);
const VERCEL_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV;

VERCEL_ENV !== 'local' &&
  Sentry.init({
    dsn: SENTRY_DSN,
    // Adjust this value in production, or use tracesSampler for greater control
    // tracesSampleRate: 1.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    replaysSessionSampleRate: SAMPLE_RATE,
    replaysOnErrorSampleRate: SAMPLE_RATE,
    integrations: [new Sentry.Replay()],
  });
